var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "cyui-layout-wrapper"
  }, [_c('cyui-layout', {
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_vm.isCustomHeader ? _c('cyui-header', {
          attrs: {
            "title": _vm.headerTitle
          },
          on: {
            "go-back": _vm.goBack,
            "on-close": _vm.handleClosePage
          },
          scopedSlots: _vm._u([{
            key: "headerRight",
            fn: function () {
              return [_c('div')];
            },
            proxy: true
          }], null, false, 378232108)
        }) : _vm._e(), _c('div', {
          staticClass: "cyui-driections-navbar"
        }, [_c('van-form', {
          on: {
            "submit": _vm.onSubmit
          }
        }, [_c('van-field', {
          attrs: {
            "readonly": "",
            "clickable": "",
            "name": "picker",
            "label": "空间选择",
            "placeholder": "请选择",
            "input-align": "right",
            "right-icon": "arrow",
            "required": "",
            "rules": [{
              required: true
            }]
          },
          on: {
            "click": function ($event) {
              _vm.showSpace = true;
            }
          },
          model: {
            value: _vm.form.spaceName,
            callback: function ($$v) {
              _vm.$set(_vm.form, "spaceName", $$v);
            },
            expression: "form.spaceName"
          }
        }), _c('van-popup', {
          attrs: {
            "round": "",
            "position": "bottom"
          },
          model: {
            value: _vm.showSpace,
            callback: function ($$v) {
              _vm.showSpace = $$v;
            },
            expression: "showSpace"
          }
        }, [_c('van-cascader', {
          attrs: {
            "title": "空间选择",
            "options": _vm.spaceList,
            "field-names": _vm.fieldNames
          },
          on: {
            "close": function ($event) {
              _vm.showSpace = false;
            },
            "change": _vm.spaceChange
          },
          model: {
            value: _vm.form.spaceId,
            callback: function ($$v) {
              _vm.$set(_vm.form, "spaceId", $$v);
            },
            expression: "form.spaceId"
          }
        })], 1), _c('van-field', {
          attrs: {
            "readonly": "",
            "clickable": "",
            "name": "picker",
            "label": "问题类型",
            "placeholder": "请选择",
            "input-align": "right",
            "right-icon": "arrow",
            "required": "",
            "rules": [{
              required: true
            }]
          },
          on: {
            "click": function ($event) {
              _vm.showQuestion = true;
            }
          },
          model: {
            value: _vm.form.questionCategoryName,
            callback: function ($$v) {
              _vm.$set(_vm.form, "questionCategoryName", $$v);
            },
            expression: "form.questionCategoryName"
          }
        }), _c('van-popup', {
          attrs: {
            "round": "",
            "position": "bottom"
          },
          model: {
            value: _vm.showQuestion,
            callback: function ($$v) {
              _vm.showQuestion = $$v;
            },
            expression: "showQuestion"
          }
        }, [_c('van-cascader', {
          attrs: {
            "title": "问题类型",
            "options": _vm.questionList,
            "field-names": _vm.questionfieldNames
          },
          on: {
            "close": function ($event) {
              _vm.showQuestion = false;
            },
            "change": _vm.questionChange
          },
          model: {
            value: _vm.form.questionCategoryId,
            callback: function ($$v) {
              _vm.$set(_vm.form, "questionCategoryId", $$v);
            },
            expression: "form.questionCategoryId"
          }
        })], 1), _c('van-field', {
          attrs: {
            "label": "问题描述",
            "rows": "3",
            "autosize": "",
            "maxlength": "100",
            "type": "textarea",
            "placeholder": "请输入",
            "input-align": "left",
            "error-message-align": "left",
            "show-word-limit": "",
            "required": "",
            "rules": [{
              required: true
            }]
          },
          model: {
            value: _vm.form.questionDes,
            callback: function ($$v) {
              _vm.$set(_vm.form, "questionDes", $$v);
            },
            expression: "form.questionDes"
          }
        }), _c('van-field', {
          attrs: {
            "name": "uploader",
            "label": "图片",
            "input-align": "right",
            "required": ""
          },
          scopedSlots: _vm._u([{
            key: "input",
            fn: function () {
              return [_c('van-uploader', {
                attrs: {
                  "after-read": _vm.afterIDRead,
                  "max-count": 2,
                  "max-size": 50 * 1024 * 1024
                },
                on: {
                  "oversize": _vm.onOversize
                },
                model: {
                  value: _vm.imgList,
                  callback: function ($$v) {
                    _vm.imgList = $$v;
                  },
                  expression: "imgList"
                }
              })];
            },
            proxy: true
          }])
        }), _c('van-field', {
          attrs: {
            "readonly": "",
            "clickable": "",
            "name": "picker",
            "label": "期望上门日期",
            "placeholder": "请选择",
            "input-align": "right",
            "right-icon": "arrow",
            "required": "",
            "rules": [{
              required: true
            }]
          },
          on: {
            "click": function ($event) {
              _vm.showDatePicker = true;
            }
          },
          model: {
            value: _vm.form.expectRepairDate,
            callback: function ($$v) {
              _vm.$set(_vm.form, "expectRepairDate", $$v);
            },
            expression: "form.expectRepairDate"
          }
        }), _c('van-popup', {
          attrs: {
            "round": "",
            "position": "bottom"
          },
          model: {
            value: _vm.showDatePicker,
            callback: function ($$v) {
              _vm.showDatePicker = $$v;
            },
            expression: "showDatePicker"
          }
        }, [_c('van-datetime-picker', {
          attrs: {
            "type": "datehour",
            "title": "选择年月日小时"
          },
          on: {
            "confirm": _vm.changeDate,
            "cancel": function ($event) {
              _vm.showDatePicker = false;
            }
          },
          model: {
            value: _vm.expectRepairDate,
            callback: function ($$v) {
              _vm.expectRepairDate = $$v;
            },
            expression: "expectRepairDate"
          }
        })], 1), _c('van-field', {
          attrs: {
            "label": "联系人",
            "placeholder": "请输入",
            "input-align": "left",
            "required": "",
            "rules": [{
              required: true
            }]
          },
          model: {
            value: _vm.form.contactsName,
            callback: function ($$v) {
              _vm.$set(_vm.form, "contactsName", $$v);
            },
            expression: "form.contactsName"
          }
        }), _c('van-field', {
          attrs: {
            "label": "联系电话",
            "placeholder": "请输入",
            "input-align": "left",
            "required": "",
            "rules": [{
              required: true
            }]
          },
          model: {
            value: _vm.form.contactsMobile,
            callback: function ($$v) {
              _vm.$set(_vm.form, "contactsMobile", $$v);
            },
            expression: "form.contactsMobile"
          }
        }), _c('van-field', {
          attrs: {
            "label": "备注",
            "rows": "3",
            "autosize": "",
            "maxlength": "100",
            "type": "textarea",
            "placeholder": "请输入",
            "input-align": "left",
            "error-message-align": "left",
            "show-word-limit": ""
          },
          model: {
            value: _vm.form.contactsRemark,
            callback: function ($$v) {
              _vm.$set(_vm.form, "contactsRemark", $$v);
            },
            expression: "form.contactsRemark"
          }
        }), _c('div', {
          staticStyle: {
            "margin": "16px"
          }
        }, [_c('van-button', {
          attrs: {
            "round": "",
            "block": "",
            "type": "info",
            "color": "#d1a36f",
            "native-type": "submit",
            "loading": _vm.loading
          }
        }, [_vm._v("提交 ")])], 1)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };